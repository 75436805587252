import React from "react";
import Footer from "components/Footer/footer";
import ContactHeader from "components/Contact-header/contact-header";
import ContactForm from "components/Contact-form/contact-form";
import DarkTheme from "layouts/Dark";
import NavbarLR from "components/NavbarLR/navbar";

const Contact = () => {
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);

  React.useEffect(() => {
    document.querySelector("body").classList.add("contact-page");

    var navbar = navbarRef.current;

    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });

    return () => {
      document.querySelector("body").classList.remove("contact-page");
    };
  }, [navbarRef]);

  return (
    <DarkTheme>
      <NavbarLR nr={navbarRef}  lr={logoRef}/>
      <ContactHeader />
      <div className="main-content">
        <ContactForm />
        <div className="map" id="ieatmaps">
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1440.9021025547086!2d172.7994094!3d-43.7561625!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d32310acc8b5e57%3A0xbacaf6faea8aac56!2s130%20Western%20Valley%20Road%2C%20Little%20River%207591!5e0!3m2!1sen!2snz!4v1702148905932!5m2!1sen!2snz" width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>

        <Footer hideBGCOLOR />
      </div>
    </DarkTheme>
  );
};

export const Head = () => {
  return (
    <>
      <title>Vie - Contact Dark</title>
    </>
  )
}

export default Contact;
